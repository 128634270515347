import axios from 'axios';
import React from 'react';

export const SUBMIT_IMAGE_UPLOAD = 'SUBMIT_IMAGE_UPLOAD';
export const GET_IMAGE_LIBRARY = 'GET_IMAGE_LIBRARY';

export function actionSubmitImageUpload(formValues) {
    const url = "/api-image/upload";
    return {
        type: SUBMIT_IMAGE_UPLOAD,
        payload: axios.post(url, formValues)
    }
}

export function actionGetImageLibrary(page_limit, offset) {
    const url = "/api-image/get-library";
    let data = {
        page_limit,
        offset
    }
    return {
        type: GET_IMAGE_LIBRARY,
        payload: axios.post(url, data)
    }
}