import axios from 'axios';

export const SUBMIT_PASSWORD_RESET_TOKEN = 'SUBMIT_PASSWORD_RESET_TOKEN';
export const SUBMIT_PASSWORD_RESET_FORM = 'SUBMIT_PASSWORD_RESET_FORM';

export function actionSubmitPasswordResetToken(token){
	const url = "/api-resetting/check-token/" + token;
	return {
		type: SUBMIT_PASSWORD_RESET_TOKEN,
		payload: axios.get(url)
	}
}

export function actionSubmitPasswordResetForm(values, token){
	const url = "/api-resetting/reset/" + token;
	let data = new URLSearchParams();
	data.append("fos_user_resetting_form[plainPassword][first]", values.fos_user_resetting_form['plainPassword']['first']);
	data.append("fos_user_resetting_form[plainPassword][second]", values.fos_user_resetting_form['plainPassword']['second']);

	return {
		type: SUBMIT_PASSWORD_RESET_FORM,
		payload: axios.post(url, data)
	}
}