import axios from 'axios';
import React from 'react';

export const SUBMIT_EUROMILLIONS_INSERT = 'SUBMIT_EUROMILLIONS_INSERT';
export const SUBMIT_EUROMILLIONS_DELETE = 'SUBMIT_EUROMILLIONS_DELETE';

export function actionSubmitEuromillionsInsert(values) {
    const url = "/api-euromillions/insert";

    let data = new URLSearchParams();
    data.append("euromillions_form[drawDate]", values['drawDate']);
    data.append("euromillions_form[ball1]", values['ball1']);
    data.append("euromillions_form[ball2]", values['ball2']);
    data.append("euromillions_form[ball3]", values['ball3']);
    data.append("euromillions_form[ball4]", values['ball4']);
    data.append("euromillions_form[ball5]", values['ball5']);
    data.append("euromillions_form[star1]", values['star1']);
    data.append("euromillions_form[star2]", values['star2']);

    return {
        type: SUBMIT_EUROMILLIONS_INSERT,
        payload: axios.post(url, data)
    }
}

export function actionSubmitEuromillionsDelete(values) {
    const url = "/api-euromillions/delete";

    let data = new URLSearchParams();
    data.append("euromillions_delete_form[drawDate]", values['drawDate']);

    return {
        type: SUBMIT_EUROMILLIONS_DELETE,
        payload: axios.post(url, data)
    }
}