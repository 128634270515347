
// file uploads
export const IMAGE_ACCEPTED_MIME_TYPES = ['image/jpeg', 'image/png'];
export const UPLOAD_MAX_SIZE = 3000000; // bytes

export const TIMEZONE = "Europe/Paris"

export const MAIN_LOGO_PATH_PNG = '/img/main-logo.png';
export const MAIN_LOGO_PATH_WEBP = '/img/main-logo.webp';
export const LOADING_INDICATOR_IMG = '/img/circles.svg';
export const IMAGE_UPLOAD_PATH = '/uploads/img';
export const THUMBNAIL_PATH = '/uploads/thb';
export const STANDARD_THUMBNAIL_WIDTH = 210;

export const CACHE_DATE = "290519";