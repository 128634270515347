import React , {lazy, Suspense} from 'react';
import { Provider } from 'react-redux';
import {
    Route,
    BrowserRouter as Router,
    Switch
} from 'react-router-dom';
import localforage from 'localforage';
import PropTypes from 'prop-types';
import PrivateRoute from './auth/auth';
import Meta from './pages/common/meta/';
import Header from './pages/common/header/';
import { Loading } from './tools/functions';
import ErrorBoundary from './pages/common/error_boundary';
import axios from 'axios';

const Fallback = (Component) => {
    return props => (
        <ErrorBoundary>
            <Suspense fallback={<Loading />}>
                <Component {...props} />
            </Suspense>
        </ErrorBoundary>
    );
  }

const HomePage = lazy(() => import('./pages/home/'));
const AdminEuromillionsEditorPage = lazy(() => import('./pages/admin_euromillions_editor'));
const AdminLotoEditorPage = lazy(() => import('./pages/admin_loto_editor'));
const AdminPageEditorPage = lazy(() => import('./pages/admin_page_editor'));
const AdminUserEditorPage = lazy(() => import('./pages/admin_user_editor'));
const Logout = lazy(() => import('./pages/common/logout/'));
const AdminImagesPage = lazy(() => import('./pages/admin_images'));
const AccountPage = lazy(() => import('./pages/account/'));
const RegistrationResultPage = lazy(() => import('./pages/registration_result/'));
const PasswordResetHandlerPage = lazy(() => import('./pages/password_reset_handler/'));
const Page404 = lazy(() => import('./pages/page404/'));
const LoginPage = lazy(() => import('./pages/login'));
const RegisterPage = lazy(() => import('./pages/register'));
const RegisterSubscriptionPage = lazy(() => import('./pages/register_subscription'));
const PasswordResetRequestPage = lazy(() => import('./pages/password_reset_request'));
const EuromillionsPage = lazy(() => import('./pages/euromillions'));
const EuromillionsResultsPage = lazy(() => import('./pages/euromillions_results'));
const LotoPage = lazy(() => import('./pages/loto'));
const LotoResultsPage = lazy(() => import('./pages/loto_results'));
const CustomPagesPage = lazy(() => import('./pages/custom_pages'));
const ModeEmploiPage = lazy(() => import('./pages/mode_emploi'));
const CommentCaMarchePage = lazy(() => import('./pages/comment_ca_marche'));
const LegalPage = lazy(() => import('./pages/legal'));
const ContactPage = lazy(() => import('./pages/contact'));

/*
ONLINE/OFFLINE STATUS comments
Using a combination of native window.online and actually sending a request to confirm.
This given the lack of reliability of window.online.
 - on componentDidMount isOnlineInterval is triggered with a high interval just to set it up.
   Also listeners are added for native events and also for custom isOffline event.
   The latter is dispatched when one of the child components axios requests fail.
- updateOnlineStatus is triggered by any of the online/offline events
   and sends a fetch requests to confirm.
   If confirmed:
   - an event is fired to the child components
   - the redux state is updated
   - a flag for offline event is added or removed from indexedDB
   - if offline, it will trigger its own setInterval to check for status
- on componentWillUnmount the subscriptions are cancelled
- child components do not rely on online/offine status to initiate axios.
  Instead they will attempt and, if fail, dispatch an offline event.
  They DO NOT listen to the native online/offline events, only to the custom ones.
- child components will show UI based upon redux state by using redux connect.

*/

class Root extends React.Component{
    constructor (props) {
        super(props);
        this.state = {
            isOnline: null,
            onlineCheckInterval: 600000,
        }
    }

    componentDidMount() {
        this.updateOnlineStatus();
        this.isOnlineInterval = setInterval(this.updateOnlineStatus, this.state.onlineCheckInterval);
        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);
       // window.addEventListener('isOffline', this.updateOnlineStatus);
        // XSRF https://stackoverflow.com/questions/54571247/do-i-have-to-use-csrf-protection-in-react-spa
        axios.get(`/api/csrf`) // Send get request to get CSRF token once site is visited.
            .then(result => {
                axios.defaults.headers.post['X-XSRF-TOKEN'] = result.data.csrfToken; // Set it in header for the rest of the axios requests.
            })
    }
        
    
    updateOnlineStatus = () => {
        const fireEvent = (name)=> {
            window.dispatchEvent(new Event(name));
        };

        const updateState = (status=0) => {
            if (status === 200) {
                if(!this.state.isOnline) {
                    this.setState({isOnline: true});
                    // send event isOnline
                    fireEvent("isOnline");
                    // update redux state
                    this.props.store.dispatch({
                        type: "ONLINE_STATUS",
                        isOnline: true
                    });
                    // clears indexedDB offline event flag
                    localforage.removeItem('offline-event-fired');
                }
            } else {
                if (this.state.isOnline === true || this.state.isOnline === null ) {
                    this.setState({isOnline: false});
                    // send event isOffline
                    fireEvent("isOffline");
                    this.props.store.dispatch({
                        type: "ONLINE_STATUS",
                        isOnline: false
                    });
                    // check if offline event already fired
                    localforage.getItem('offline-event-fired').then((data) => {
                        if (data === null) {
                            localforage.setItem('offline-event-fired', true);
                        }
                    })
                    // setInterval to check status
                    clearInterval(this.isOnlineInterval); // clear existing one
                    this.isOnlineInterval = setInterval(this.checkIsOnline, 30000);                 
                }
            }
        }

        fetch("/favicon32.jpg").then((response) => {
            if(response.status === 200) {
                //is online 
                updateState(response.status);
            } else {
                //is offline 
                updateState();
            }
        }).catch((error) => {
            //is offline 
            console.log(error);
                updateState();
        })
    }

    componentWillUnmount () {
        clearInterval(this.isOnlineInterval);
        window.removeEventListener('online', this.updateOnlineStatus);
        window.removeEventListener('offline', this.updateOnlineStatus);
        window.removeEventListener('isOffline', this.updateOnlineStatus);
    }

    render () {
        return(
            <Provider store={this.props.store}>
                <Router> 
                    <React.Fragment>
                        <ErrorBoundary>
                            <Suspense fallback={<Loading />}>
                                <Meta />
                            </Suspense>
                        </ErrorBoundary>
                        <ErrorBoundary>
                            <Suspense fallback={<Loading />}>
                                <Header />
                            </Suspense>
                        </ErrorBoundary>
                        <Switch>
                            <PrivateRoute exact path="/articles/:slug?" component={Fallback(CustomPagesPage)} />
                            <Route path="/resetting/reset/:token" component={Fallback(PasswordResetHandlerPage)} />
                            <Route path="/registration-result/:status" component={Fallback(RegistrationResultPage)} />
                            <PrivateRoute exact path="/euromillions" component={Fallback(EuromillionsPage)} />
                            <PrivateRoute exact path="/loto" component={Fallback(LotoPage)} />
                            <PrivateRoute exact path="/compte" component={Fallback(AccountPage)} />
                            <Route exact path="/logout" component={Fallback(Logout)} />
                            <Route exact path="/connexion" component={Fallback(LoginPage)} />
                            <Route path="/password-reset-request" component={Fallback(PasswordResetRequestPage)} />
                            <Route exact path="/inscription" component={Fallback(RegisterPage)} />
                            <Route exact path="/souscription/:result" component={Fallback(RegisterSubscriptionPage)} />
                            <PrivateRoute exact path="/admin/pages/editeur/ajouter" component={Fallback(AdminPageEditorPage)} />
                            <PrivateRoute exact path="/admin/pages/editeur/modifier" component={Fallback(AdminPageEditorPage)} />
                            <PrivateRoute exact path="/admin/euromillions/editeur" component={Fallback(AdminEuromillionsEditorPage)} />
                            <PrivateRoute exact path="/admin/membres/editeur" component={Fallback(AdminUserEditorPage)} />
                            <PrivateRoute exact path="/admin/loto/editeur" component={Fallback(AdminLotoEditorPage)} />
                            <PrivateRoute exact path="/admin/images" component={Fallback(AdminImagesPage)} />
                            <PrivateRoute exact path="/euromillions/resultats" component={Fallback(EuromillionsResultsPage)} />
                            <PrivateRoute exact path="/loto/resultats" component={Fallback(LotoResultsPage)} />
                            <PrivateRoute exact path="/mode-emploi" component={Fallback(ModeEmploiPage)} />
                            <Route exact path="/comment-ca-marche" component={Fallback(CommentCaMarchePage)} />
                            <Route exact path="/mentions-legales" component={Fallback(LegalPage)} />
                            <Route exact path="/contact" component={Fallback(ContactPage)} />
                            <Route exact path="/" component={Fallback(HomePage)} />
                            <Route component={Fallback(Page404)} />
                        </Switch>
                    </React.Fragment>
                </Router>
        </Provider>
       
        )
    }
}

Root.propTypes = {
    store: PropTypes.object.isRequired
};




export default Root;
