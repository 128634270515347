import axios from 'axios';
import React from 'react';

export const SUBMIT_PAGE_INSERT = 'SUBMIT_PAGE_INSERT';
export const SUBMIT_PAGE_EDIT = 'SUBMIT_PAGE_EDIT';

export function actionSubmitPageInsert(values) {
    const url = "/api-page/insert";

    let data = new URLSearchParams();
    data.append("page_form[slug]", values['slug']);
    data.append("page_form[title]", values['title']);
    data.append("page_form[content]", values['content']);

    return {
        type: SUBMIT_PAGE_INSERT,
        payload: axios.post(url, data)
    }
}

export function actionSubmitPageEdit(values) {
    const url = "/api-page/edit";

    return {
        type: SUBMIT_PAGE_EDIT,
        payload: axios.post(url, values)
    }
}

