import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import classNames from 'classnames';

const styles = theme => ({
    root: {
        flexGrow: 1,
        position: "fixed",
        bottom: "0%",
        zIndex: 11000,
        width: "100%",
        maxWidth: "1200px"
    },
    grow: {
        flexGrow: 1,
        fontSize: '1.25rem',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            fontSize: '.75rem',
        },
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    toolBar: {
        display: "grid",
        marginTop: "10px"
    },
    cssRoot: {
        width: "150px",
        [theme.breakpoints.down('xs')]: {
            fontSize: '.75rem',
        },
    },
    buttonContainer: {
        display: "grid",
        gridTemplateColumns: "200px auto",
        justifyContent: "center",
        margin: "10px 0",
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: "1fr 1fr",
            paddingBottom: "20px"
        },
    },
    aButton: {
        textTransform: "uppercase",
        fontSize: "1rem",
        color: "yellow",
    }
});

function RgpdDialog(props) {
    const { classes, handleCloseRgpdDialog } = props;
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar className={classes.toolBar}>
                    <Typography variant="h6" color="inherit" className={classes.grow}>
                        En poursuivant votre navigation, vous acceptez l'utilisation des cookies. 
                    </Typography>
                    <div className={classes.buttonContainer}>
                        <ButtonBase
                            component={Link}
                            to="/mentions-legales"
                            className={classNames(classes.margin, classes.cssRoot, classes.aButton)}
                            >
                            Plus des details
                        </ButtonBase>
                        <ButtonBase
                            className={classNames(classes.margin, classes.cssRoot, classes.aButton)}
                            onClick={handleCloseRgpdDialog}
                        >
                            J'accepte
                        </ButtonBase>
                    </div>

                </Toolbar>
            </AppBar>
        </div>
    );
}

RgpdDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RgpdDialog);