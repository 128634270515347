import axios from 'axios';
import React from 'react';

export const SUBMIT_PASSWORD_RESET_REQUEST = 'SUBMIT_PASSWORD_RESET_REQUEST';

export function actionSubmitPasswordResetRequest(values){
    const url = "/resetting/send-email";
    let data = new URLSearchParams();
    data.append("username", values["username"]);

	return {
		type: SUBMIT_PASSWORD_RESET_REQUEST,
		payload: axios.post(url, data)
	}
}