import axios from 'axios';

export const GET_ALL_LOTO_RESULTS = 'GET_ALL_LOTO_RESULTS';

export function actionGetAllLotoResults() {
    const url = `/api-loto/get-results`;

    let data = {
        number_of_draws: 'all'
    }

    return {
        type: GET_ALL_LOTO_RESULTS,
        payload: axios.post(url, data)
    }
}