import axios from 'axios';

export const GET_LOTO_RESULTS = 'GET_LOTO_RESULTS';

export function actionGetLotoResults() {
    const url = `/api-loto/get-results`;

    return {
        type: GET_LOTO_RESULTS,
        payload: axios.get(url)
    }
}