import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase';
import Img from "react-webp-image";
import localforage from 'localforage';
import PropTypes from 'prop-types';
import Status from './components/status';
import SessionHandler from '../session_handler/';
import NotifierDialog from '../notifier_dialog';
import {
    actionGetInitialDataForOffline,
    actionGetUserDataForOffline
} from './actions/';
import { MAIN_LOGO_PATH_PNG, MAIN_LOGO_PATH_WEBP } from '../../../parameters';
import { Now } from '../../../tools/functions';
import RgpdDialog from '../../common/rgpd_dialog';

//const reg = /^\/([a-z]*)|^\/([a-z\/])\//;


class Header extends React.Component {
	constructor(props){
        super(props);
        this.state = {
            username: false,
            firstName: false,
            isRgpd: false,
            notification: {
                status: '',
                title: '',
                message: '',
                errors: {}
            },
        }
    }


    componentDidMount() {
        // check if is online and initial data exists in indexeddb, if not fetch it
        if (this.props.online_status.isOnline) {
            localforage.getItem('pages').then(value => {
                if (value === null) {
                    this.getInitialDataForOffline();
                }
            });
        }
        window.addEventListener('get_user_data', (event) => {
            this.getUserDataForOffline()
        });
        window.addEventListener('get_initial_data', (event) => {
            this.getInitialDataForOffline();
        });
        this.setUsername();
        this.setIsRgpd();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname != prevProps.location.pathname) {
            if (!this.state.username) {
                this.setUsername();
            }
            this.setIsRgpd();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('get_user_data', this.getUserDataForOffline);
        window.removeEventListener('get_initial_data', this.getInitialDataForOffline);

    }

    setIsRgpd = () => {
        const isRgpdRoute = ["/", "/comment-ca-marche", "/contact"];
        localforage.getItem('isRgpd').then((value) => {
            if (!value) { // if no value in indexeddb proceed to check the route
                if (isRgpdRoute.includes(this.props.location.pathname)) {
                    this.setState({ isRgpd: true });
                } else {
                    this.setState({ isRgpd: false });
                }
            } else { // if isRgpd is set on indexeddb, do not show dialog
                this.setState({ isRgpd: false });
            }
        })

    }

    setUsername = () => {
        let username = null;
        if (this.props.submit_login.data && this.props.submit_login.data.profile) {
            username = { username: this.props.submit_login.data.profile.username }
            this.setState({
                username,
                firstName: this.props.submit_login.data.profile.first_name,
            })
        } 
        if (!username) {
            localforage.getItem('bda_session').then((value) => {
                if (value) {
                    this.setState({
                        username: { username: value.username },
                        firstName: value.first_name
                    });
                }
            })
        }
        if (!username) {
            this.setState({
                username: false,
                firstName: false
            });
        }
    }

    getInitialDataForOffline = () => {
        // get all initial data for offline from remote
        let getData = new Promise((resolve) => {
            resolve(this.props.actionGetInitialDataForOffline());
        });
        getData.then((result) => {
            localforage.setItem('last_active_server', Now());
            let data = result.payload.data;
            // set indexeddb
            localforage.setItem('timestamp-initialdata', new Date());
            localforage.setItem('pages', data.pages);
        }).catch((error) => {
            console.log(error);
        });
    }

    getUserDataForOffline = () => {
        const setUsername = new Promise((resolve) => {
            resolve(this.setUsername());
        });
        setUsername.then(() => {
            if (!this.state.username) {
                return;
            }
            let getUserData = new Promise((resolve) => {
                resolve(this.props.actionGetUserDataForOffline(this.state.username));
            });

            getUserData.then((result) => {
                localforage.setItem('last_active_server', Now());
                if (result.error) {
                    window.addEventListener("isOnline", this.getUserDataForOffline);
                    // check if offline event already fired
                    localforage.getItem('offline-event-fired').then((data) => {
                        if (data === null) {
                            window.dispatchEvent(new Event('offline'));
                            localforage.setItem('offline-event-fired', true);
                        }
                    })
                } else {
                    const data = result.payload.data;
                    localforage.setItem('profile', result.payload.data.profile);
                    localforage.setItem('euromillions_results', data.euromillions_results);
                    localforage.setItem('loto_results', data.loto_results);
                    localforage.setItem('loto_history6', data.loto_history6);
                    localforage.setItem('euromillions_history6', data.euromillions_history6);
                    localforage.setItem('timestamp-userdata', new Date());
                }
            }).catch((error) => {
                console.log(error);
            })
        })


    }

    handleNotificationDismiss =  () => {
        this.setState({
            notification: {
                status: '',
                title: '',
                message: '',
                errors: {}
            }
        })
    }

    handleCloseRgpdDialog = () => {
        this.setState({ isRgpd: false });
        localforage.setItem('isRgpd', true);
    }

	render() {
        const { location } = this.props;

		return (
            <React.Fragment> 
                {
                    this.state.isRgpd ?
                        <RgpdDialog handleCloseRgpdDialog={this.handleCloseRgpdDialog} />
                        :
                        null
                }
                <header>
                    <div className="header_top grid">
                        <div className="mobile_nav_placeholder">

                        </div>
                        <ButtonBase 
                            className="branding"
                            component={Link} 
                            to="/"
                            aria-label="gagnerloto logo"
                        >
                            <Img 
                                src={MAIN_LOGO_PATH_PNG} 
                                webp={MAIN_LOGO_PATH_WEBP}
                                alt="logo" 
                            />
                        </ButtonBase>
                        <Status
                            username={this.state.username}
                            firstName={this.state.firstName}
                        />
                    </div> 
                    {
                        this.state.username ?
                            <div className="mobile_welcome">
                                <span>Bienvenue</span>
                                <span className="status_user">{this.state.firstName}</span>
                            </div>
                            :
                            null
                    }

                    <div className="auth">
                        <SessionHandler location={location}/>
                    </div>
                    <NotifierDialog 
                        notification={this.state.notification}
                        handleNotificationDismiss={this.handleNotificationDismiss}
                    />
                </header>	
            </React.Fragment>	
		);
	}
}

Header.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        show_map: state.show_map,
        online_status: state.online_status,
        get_initial_data_for_offline: state.get_initial_data_for_offline,
        get_user_data_for_offline: state.get_user_data_for_offline,
        check_session: state.check_session,
        submit_login: state.submit_login
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        actionGetInitialDataForOffline,
        actionGetUserDataForOffline
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));