import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    overrides: {
      MuiDialog: { 
        paperFullWidth: { 
          margin: 0,
        },
      },
    },
  });


class NotifierDialog extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            open: true,
        }
    }


    errors = () => {
        const err_obj = this.props.notification.errors;
        const errors = Object.keys(err_obj).map(key => err_obj[key])
        if (errors.length > 0) {
            let output = errors.map((error, index) => {
                return (
                 <li key={index} >{error} </li>
                )
            });
            return (
                <ul>
                    {output}
                </ul>
            )
        }
        return null;
    }

    actions = () => {
        switch(this.props.notification.status) {
            case 'ok_and_dismiss':
                return (
                    <Button 
                        id="dismiss_notification" 
                        onClick={this.props.handleNotificationDismiss} 
                        color="primary" autoFocus>
                        Fermer ceci
                    </Button>
                );
            case 'error':	
                return (
                    <Button 
                        id="dismiss_notification" 
                        onClick={this.props.handleNotificationDismiss} 
                        color="primary" autoFocus>
                        Fermer ceci
                    </Button>
                );
            case 'show_session_expire_warning':
                return (
                    <React.Fragment>
                        <Button 
                            id="session_expire"
                            onClick={this.props.handleSessionWarning}  
                            color="primary">
                            Laisser expirer
                        </Button>
                        <Button 
                            id="session_extend"
                            onClick={this.props.handleSessionWarning}  
                            color="primary" autoFocus>
                            Prolonger
                        </Button>
                    </React.Fragment>
                );
            case 'show_logout_idle_message':
                return (
                    <Button 
                        id="session_logout"
                        onClick={this.props.handleSessionWarning} 
                        color="primary" autoFocus>
                        Fermer ceci
                    </Button>
                );
            case 'toggle_enable_user':
                return (
                    <div>
                        <Button
                            id="proceed"
                            variant="outlined"
                            color="primary"
                            onClick={this.props.handleToggleEnableUser}
                        >
                            Confirmer
                        </Button>
                        <Button
                            id="cancel"
                            variant="outlined"
                            color="primary"
                            onClick={this.props.handleToggleEnableUser}
                        >
                            Annuler
                        </Button>
                    </div>
                );
            case 'confirm_delete':
                return (
                    <div>
                        <Button
                            id="confirmed"
                            variant="outlined"
                            color="primary"
                            onClick={this.props.handleNotificationDismiss}
                        >
                            Confirmer
                        </Button>
                        <Button
                            id="cancelled"
                            variant="outlined"
                            color="primary"
                            onClick={this.props.handleNotificationDismiss}
                        >
                            Annuler
                        </Button>
                    </div>
                )
            default:
                return null;
        }
    }

    render() {
        if(this.props.notification.status != "") {
            return(   
                <MuiThemeProvider theme={theme}> 
                    <Dialog 
                        className="notification"
                        open={this.state.open}
                        fullWidth={true}
                    >
                        <DialogTitle className="notification_title">
                                {this.props.notification.title}
                        </DialogTitle>
                        <DialogContent className="notification_content">
                            <div>{this.props.notification.message}</div>
                            {this.errors()}
                        </DialogContent>
                        <DialogActions className="notification_action">
                            {this.actions()}
                        </DialogActions>
                    </Dialog>
                </MuiThemeProvider> 
            )
        }
        return null;
    }
}

NotifierDialog.propTypes = {
};

export default NotifierDialog;
