import React from 'react';
import { Link } from 'react-router-dom';
import IconPowerSettingsOn from '@material-ui/icons/PowerSettingsNewTwoTone';
import IconPowerSettingsOff from '@material-ui/icons/PowerSettingsNewRounded';
import ButtonBase from '@material-ui/core/ButtonBase';
import PropTypes from 'prop-types';

const Status = (props) => {

    if (props.username) {
        return (
            <React.Fragment>
                <div className="status">
                    <div>
                        <span>Bienvenue</span>
                        <span className="status_user">{props.firstName}</span>
                    </div>
                    <ButtonBase
                        component={Link}
                        to="/logout"
                        aria-label="deconnexion"
                    >
                        Déconnexion
                    </ButtonBase>
                </div>
                <div className="status_mobile">
                        <ButtonBase
                            component={Link}
                        to="/logout"
                        aria-label="deconnexion"
                        >
                            <IconPowerSettingsOff />
                        </ButtonBase>
                </div>
            </React.Fragment>

        )
    } else {
        return (
            <React.Fragment>
                <div className="status">
                    <ButtonBase
                        component={Link}
                        to="/connexion"
                        aria-label="connexion"
                    >
                        Connexion
                    </ButtonBase>
                    <ButtonBase
                        component={Link}
                        to="/inscription"
                        aria-label="inscription"
                    >
                        Inscription
                    </ButtonBase>
                </div>
                <div className="status_mobile">
                    <ButtonBase
                        component={Link}
                        to="/connexion"
                        aria-label="connexion"
                    >
                        <IconPowerSettingsOn />
                    </ButtonBase>
                </div>
            </React.Fragment>

        )
    }	
}

Status.propTypes = {
};


export default Status;