import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import check_session from './common/session_handler/reducers/reducer_check_session';
import create_subscription from './register/reducers/reducer_create_subscription';
import get_all_euromillions_results from './euromillions_results/reducers/reducer_get_all_euromillions_results';
import get_all_loto_results from './loto_results/reducers/reducer_get_all_loto_results';
import get_all_users from './admin_user_editor/reducers/reducer_get_all_users';
import get_euromillions_results from './euromillions/reducers/reducer_get_euromillions_results';
import get_image_library from './admin_images/reducers/reducer_get_image_library';
import get_initial_data_for_offline from './common/header/reducers/reducer_get_initial_data_for_offline';
import get_custom_pages from './custom_pages/reducers/reducer_get_custom_pages';
import get_loto_results from './loto/reducers/reducer_get_loto_results';
import get_subscription_details from './register_subscription/reducers/reducer_get_subscription_details';
import get_user_data_for_offline from './common/header/reducers/reducer_get_user_data_for_offline';
import online_status from '../reducer_online_status';
import submit_email_edit_request_form from './account/reducers/reducer_submit_email_edit_request_form';
import submit_euromillions_delete from './admin_euromillions_editor/reducers/reducer_submit_euromillions_delete';
import submit_euromillions_insert from './admin_euromillions_editor/reducers/reducer_submit_euromillions_insert';
import submit_first_name_edit_form from './account/reducers/reducer_submit_first_name_edit_form';
import submit_image_upload from './admin_images/reducers/reducer_submit_image_upload';
import submit_login from './login/reducers/reducer_submit_login';
import submit_loto_delete from './admin_loto_editor/reducers/reducer_submit_loto_delete';
import submit_loto_insert from './admin_loto_editor/reducers/reducer_submit_loto_insert';
import submit_page_edit from './admin_page_editor/reducers/reducer_submit_page_edit';
import submit_page_insert from './admin_page_editor/reducers/reducer_submit_page_insert';
import submit_password_edit_form from './account/reducers/reducer_submit_password_edit_form';
import submit_password_reset_form from './password_reset_handler/reducers/reducer_submit_password_reset_form';
import submit_password_reset_request from './password_reset_request/reducers/reducer_submit_password_reset_request';
import submit_password_reset_token from './password_reset_handler/reducers/reducer_submit_password_reset_token';
import submit_payment_in_full_info from './register/reducers/reducer_submit_payment_in_full_info';
import submit_register from './register/reducers/reducer_submit_register';
import submit_user_email_edit_form from './admin_user_editor/reducers/reducer_submit_user_email_edit_form';
import submit_user_payment_insert_form from './admin_user_editor/reducers/reducer_submit_user_payment_insert_form';
import submit_user_registration_by_admin_form from './admin_user_editor/reducers/reducer_submit_user_registration_by_admin_form';
import toggle_enable_user from './admin_user_editor/reducers/reducer_toggle_enable_user';


const rootReducer = combineReducers({
    form: formReducer,
    check_session,
    create_subscription,
    get_all_euromillions_results,
    get_all_loto_results,
    get_all_users,
    get_euromillions_results,
    get_image_library,
    get_initial_data_for_offline,
    get_custom_pages,
    get_loto_results,
    get_subscription_details,
    get_user_data_for_offline,
    online_status,
    submit_email_edit_request_form,
    submit_euromillions_delete,
    submit_euromillions_insert,
    submit_first_name_edit_form,
    submit_image_upload,
    submit_login,
    submit_loto_delete,
    submit_loto_insert,
    submit_page_edit,
    submit_page_insert,
    submit_password_edit_form,
    submit_password_reset_form,
    submit_password_reset_request,
    submit_password_reset_token,
    submit_payment_in_full_info,
    submit_register,
    submit_user_email_edit_form,
    submit_user_payment_insert_form,
    submit_user_registration_by_admin_form,
    toggle_enable_user
});

export default rootReducer;