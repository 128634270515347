import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";


class Meta extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            articleSlug: false,
        }
    }

    componentDidMount() {
        if (this.props.location.pathname.match(/\/article.*/)) {
            const params = this.props.location.pathname.split('/');
            if (params.length === 3) {
                this.setState({
                    articleSlug: params[2]
                }) 
            }
        }
    }

    componentDidUpdate(prevProps) {
        // TODO this is a workaround to get params awaiting below
        // https://github.com/ReactTraining/react-router/pull/6159
        if (this.props.location.pathname !== prevProps.location.pathname) {
            if (this.props.location.pathname.match(/\/articles.*/)) {
                const params = this.props.location.pathname.split('/');
                if (params.length === 3) {
                    this.setState({
                        articleSlug: params[2]
                    })
                }
            }
        }
    }


    render() {
        const {location} = this.props;
        if (location.pathname === '/') {
            return (
                <Helmet>
                    <title>Systeme probix - plus de chance de gagner au loto et euromillions</title>
                    <meta name="description" content="Comment gagner au loto ou à l'euromillions ? Notre système, truc, astuce, méthode, vous donne jusqu'au double de chance de cocher les bons numéros." />
                    <link rel="canonical" href="https://gagnerloto.org/"></link>
                    <meta property="og:url" content="https://gagnerloto.org"></meta>
                    <meta property="og:title" content="gagnerloto.org - Systeme probix pour jouer au loto et euromillions"></meta>
                    <meta property="og:image" content="https://gagnerloto.org/img/android-launchericon-192-192.png"></meta>
                    <link rel="shortcut icon" href="https://gagnerloto.org/favicon.ico" type="image/icon"></link>
                    <link rel="icon" href="https://gagnerloto.org/favicon.ico" type="image/icon"></link>
                </Helmet>
            )
        } else if (location.pathname.match(/\/articles.*/)) {
            if (this.state.articleSlug) {
                return (
                    <Helmet>
                        <title>{this.state.articleSlug}</title>
                        <meta name="description" content={this.state.articleSlug} />
                    </Helmet>
                )
            } 
            return (
                <Helmet>
                    <title>Articles</title>
                    <meta name="description" content="Articles" />
                </Helmet>
            )

        } else if (location.pathname.match(/^\/euromillions$/)) {
            return (
                <Helmet>
                    <title>Euromillions</title>
                    <meta name="description" content="euromillions" />
                </Helmet>
            )
        } else if (location.pathname.match(/^\/loto$/)) {
            return (
                <Helmet>
                    <title>Loto</title>
                    <meta name="description" content="loto" />
                </Helmet>
            )
        } else if (location.pathname.match(/\/compte/)) {
            return(
                <Helmet>
                    <title>Compte</title>
                    <meta name="description" content="compte" />
                </Helmet>
            )
        } else if (location.pathname.match(/\/admin\/.*/)) {
            return (
                <Helmet>
                    <title>admin</title>
                    <meta name="description" content="admin" />
                </Helmet>
            )
        } else if (location.pathname.match(/\/euromillions\/resultats/)) {
            return (
                <Helmet>
                    <title>Euromillions - resultats</title>
                    <meta name="description" content="resultats euromillions" />
                </Helmet>
            )
        } else if (location.pathname.match(/\/loto\/resultats/)) {
            return (
                <Helmet>
                    <title>Loto - resultats</title>
                    <meta name="description" content="resultats loto" />
                </Helmet>
            )
        } else if (location.pathname.match(/\/resetting.*/)) {
            return (
                <Helmet>
                    <title>Reinitilisation mot de passe</title>
                    <meta name="description" content="Reinitilisation mot de passe" />
                    <meta name="robots" content="noindex" />
                </Helmet>
            )
        } else if (location.pathname.match(/\/inscription/)) {
            return (
                <Helmet>
                    <title>inscription</title>
                    <meta name="description" content="inscription" />
                    <meta name="robots" content="noindex" />
                </Helmet>
            )
        } else if (location.pathname.match(/\/mode-emploi/)) {
            return (
                <Helmet>
                    <title>Systeme Probix - euromillions et loto mode emploi</title>
                    <meta name="description" content="mode emploi probix" />
                </Helmet>
            )
        } else if (location.pathname.match(/\/registration-result.*/)){
            return (
                <Helmet>
                    <title>Enregistrement resultat</title>
                    <meta name="description" content="enregistrement resultat" />
                    <meta name="robots" content="noindex" />
                </Helmet>
            )
        } else if (location.pathname.match(/\/souscription-result.*/)) {
            return (
                <Helmet>
                    <title>Enregistrement resultat</title>
                    <meta name="description" content="souscription resultat" />
                    <meta name="robots" content="noindex" />
                </Helmet>
            )
        } else if (location.pathname.match(/\/connexion.*/)) {
            return (
                <Helmet>
                    <title>Connexion</title>
                    <meta name="description" content="connexion" />
                    <meta name="robots" content="noindex" />
                </Helmet>
            )
        } else if (location.pathname.match(/\/mentions-legales.*/)) {
            return (
                <Helmet>
                    <title>Mentions legales</title>
                    <meta name="description" content="mentions legales" />
                    <meta name="robots" content="noindex" />
                </Helmet>
            )
        } else if (location.pathname.match(/\/contact.*/)) {
            return (
                <Helmet>
                    <title>Contact</title>
                    <meta name="description" content="contact" />
                    <meta name="robots" content="noindex" />
                </Helmet>
            )
        } else if (location.pathname.match(/\/password-reset-request/)) {
            return (
                <Helmet>
                    <title>Demande reinitialisation mot de passe</title>
                    <meta name="description" content="Demande reinitialisation mot de passe" />
                    <meta name="robots" content="noindex" />
                </Helmet>
            )
        } else if (location.pathname.match(/\/comment-ca-marche/)) {
            return (
                <Helmet>
                    <title>Comment ca marche systeme probix - loto et euromillions</title>
                    <meta name="description" content="comment ca marche le systeme probix pour gagner loto et euromillions" />
                    <link rel="canonical" href="https://gagnerloto.org/comment-ca-marche"></link>
                    <meta property="og:url" content="https://gagnerloto.org/comment-ca-marche"></meta>
                    <meta property="og:title" content="gagnerloto.org - Comment ca marche le systeme probix pour jouer au loto et euromillions"></meta>
                    <meta property="og:image" content="https://gagnerloto.org/img/android-launchericon-192-192.png"></meta>
                </Helmet>
            )
        } else {
            return null;
        }
    }
}

Meta.propTypes = {

};


export default withRouter(Meta);