import React from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { error: null, errorInfo: null };
    }
  /*
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  */
    componentDidCatch(error, errorInfo) {
      // TODO You can also log the error to an error reporting service
      //logErrorToMyService(error, info);
      this.setState({
        error: error,
        errorInfo: errorInfo
      })
    }
  
    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
              <div>
                <h2>Something went wrong.</h2>
                <details style={{ whiteSpace: 'pre-wrap' }}>
                  {this.state.error && this.state.error.toString()}
                  <br />
                  {this.state.errorInfo.componentStack}
                </details>
              </div>
            );
          }
  
      return this.props.children; 
    }
  }

 ErrorBoundary.propTypes = {

};

export default ErrorBoundary;