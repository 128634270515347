import React from 'react';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import { InputAdornment } from '@material-ui/core';
import { LOADING_INDICATOR_IMG } from '../parameters';


export const renderInput = (field) => {
    const { meta: { touched, error } } = field;
    return(
        <React.Fragment>
            <TextField 
                type={field.type} 
                id={field.id} 
                label={field.label}
                margin="none"
                helperText={field.helperText}
                fullWidth={true}
                onKeyUp={field.onKeyUp}
                className={field.className}
                placeholder={field.placeholder} 
                disabled={field.disabled}
                autoFocus={field.autoFocus}
                {...field.input} 
                variant={field.variant}
            />
            <span className="form_error">{touched ? error : ''}</span>
        </React.Fragment>
    );
}

export const renderPassword = (field) => {
    const { meta: { touched, error, warning } } = field;
    return (
        <React.Fragment>
            <TextField
                type={field.type}
                id={field.id}
                label={field.label}
                margin="none"
                helperText={field.helperText}
                fullWidth={true}
                onKeyUp={field.onKeyUp}
                className={field.className}
                placeholder={field.placeholder}
                disabled={field.disabled}
                autoFocus={field.autoFocus}
                {...field.input}
                variant={field.variant}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <RemoveRedEye
                                onClick={field.handleToggleVisiblePassword}
                                className="pointer"
                            />
                        </InputAdornment>
                    ),
                }}
            />
            <span className="form_error">{touched ? error : ''}</span>
            <span className="form_warning">{touched ? warning : ''}</span>
        </React.Fragment>
    );
}


export const renderHiddenInput = (field) => {
    return(
        <React.Fragment>
            <input
                type={field.type} 
                id={field.id} 
                {...field.input} 
            />
        </React.Fragment>
    );
}

export const renderTextArea = (field) => {
    const {meta: {touched,error} } = field;
    return(
        <React.Fragment>
            <TextField 
                fullWidth={true}
                type={field.type} 
                id={field.id} 
                margin="none"
                className={field.className}
                label={field.label}
                placeholder={field.placeholder}
                disabled={field.disabled} 
                variant={field.variant}
                {...field.input} 
                multiline={true}
            />
            <FormHelperText>{field.helperText}</FormHelperText>
            <span className="form_error">{touched ? error : ''}</span>
        </React.Fragment>
    );
}

export const renderSelect = ({
    id,
    input,
    label,
    disabled,
    helperText,
    meta: { touched, error },
    children,
    ...custom
  }) => (
        <React.Fragment>
            <FormControl>
                <InputLabel htmlFor={id}>{label}</InputLabel>
                <Select
                    id={id}
                    {...input}
                    onChange={( value) => input.onChange(value)}
                    children={children}
                    {...custom}
                />
                <FormHelperText>{helperText}</FormHelperText>
                <span className="form_error">{touched ? error : ''}</span>
            </FormControl>
        </React.Fragment>

  )


export class renderRadio extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: ''
        }
    }

    handleChange = event => {
        this.setState({ value: event.target.value });
    };

    render () {
        const {meta: {touched,error} } = this.props;
        return (
            <React.Fragment>
                <RadioGroup
                    aria-label={this.props.input.name}
                    name={this.props.input.name}
                    row={this.props.row}
                    value={this.state.value}
                    onChange={this.handleChange}
                >
                    {this.props.options.map(o => 
                        <FormControlLabel
                            key={o.id}
                            control={<Radio color="primary" />}
                            label={o.title}
                            labelPlacement="start"
                            type={this.props.type}
                            id={o.id}
                            {...this.props.input}
                            value={o.value}
                            checked={this.props.input.value}
                        />
                    )}
                </RadioGroup>
                <span className="form_error">{touched ? error : ''}</span>
            </React.Fragment>
        );
    }

}

export const renderCheckBox = (field) => {
    const {meta: {touched,error} } = field;
        return (
            <React.Fragment>
                <FormControlLabel
                    control={
                        <Checkbox
                            type={field.type}
                            id={field.id}
                            {...field.input}
                            value={field.value}
                            checked={field.input.value}
                            color="primary"
                        />
                    }
                    label={field.text}
                />
                <span className="form_error">{touched ? error : ''}</span>
            </React.Fragment>
        );
}

// this file input in case of need 
// for now, using regular <input type="file" 
// and handling validation and insert to form value onChange and onSubmit
// from https://gist.github.com/barraponto/c370c17b2499c36a625fe1326c57ab21

const handleChange = (handler) => ({target: {files}}) =>
  handler(files.length ? {file: files[0], name: files[0].name} : {});

export const renderFileInput = ({
  input: {onChange, onBlur, value: omitValue, ...inputProps},
  meta: omitMeta,
  ...props
}) => (
  <input type="file"
    onChange={handleChange(onChange)} onBlur={handleChange(onBlur)}
    {...inputProps} {...props} />
);
// this replaces lodash get()
// from https://gist.github.com/jeneg/9767afdcca45601ea44930ea03e0febf

export const get = (obj, path, def) => (() => typeof path === 'string' ? path.replace(/\[(\d+)]/g,'.$1') : path.join('.'))()
.split('.')
.filter(Boolean)
.every(step => ((obj = obj[step]) !== undefined)) ? obj : def

export const LoadingVisibility = (props) => {
    if (props.pastDelay) {
      return (
        <div className="loading_indicator">
            <img src={LOADING_INDICATOR_IMG} alt="loading" />
        </div>
      )
    } else {
      return null;
    }
  }

export const Loading = (props) => {
    return (
        <div className="loading_indicator">
            <img src={LOADING_INDICATOR_IMG} alt="loading" />
        </div>
    )
}


export const showtime = (base_date_string) => {
    let base_date = new Date(base_date_string);
    let today = new Date();
    const utc1 = Date.UTC(base_date.getFullYear(), base_date.getMonth(), base_date.getDate());
    const utc2 = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
    const diffDays = Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24));
    let date = base_date.getDate();
    let hours = base_date.getHours();
    let minutes = base_date.getMinutes();
    let date_out = '';
    if (diffDays === 0) {
        date_out = `Today ${hours}:${minutes}`;
    } else if (diffDays === 1) {
        date_out = `Yesterday ${hours}:${minutes}`;
    } else if (diffDays > 1 && diffDays < 7) {
        date_out = (base_date.toLocaleString('en-us', {  weekday: 'long' }));
        date_out = `${date_out} ${hours}:${minutes}`;
    } else {
        let month_out = (base_date.toLocaleString('en-us', {  month: 'short' }));
        date_out = `${month_out} ${date}`;
    }
    return date_out;
}

// A few JavaScript Functions for Images and Files
// Author: Justin Mitchel
// Source: https://kirr.co/ndywes
// https://github.com/codingforentrepreneurs/Try-Reactjs/blob/master/src/learn/ResuableUtils.js

// Convert a Base64-encoded string to a File object
export function base64StringtoFile (base64String, filename) {
    var arr = base64String.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, {type: mime})
  }
  
  // Download a Base64-encoded file
  
  export function downloadBase64File (base64Data, filename) {
    var element = document.createElement('a')
    element.setAttribute('href', base64Data)
    element.setAttribute('download', filename)
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }
  
  // Extract an Base64 Image's File Extension
  export function extractImageFileExtensionFromBase64 (base64Data) {
    return base64Data.substring('data:image/'.length, base64Data.indexOf(';base64'))
  }
  
  // Base64 Image to Canvas with a Crop
  // original function was adapted to draw a resized image to canvas
  // in this case with an aspect ratio of 1:1, and width = maxWidth
  export function image64toCanvasRef (canvasRef, image64, pixelCrop, maxWidth) {
    const canvas = canvasRef // document.createElement('canvas');
    //canvas.width = pixelCrop.width
    //canvas.height = pixelCrop.height
    canvas.width = maxWidth;
    canvas.height = maxWidth;
    const ctx = canvas.getContext('2d')
    const image = new Image()
    image.src = image64
    image.onload = function () {
      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        //pixelCrop.width, 
        //pixelCrop.height
        maxWidth,
        maxWidth
      )
    }
    return image;
  }

export function rangeGenerator(start, stop, step = 1) {
    return Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => x + y * step)
}

export function stringToUrlSlug(string) {
    const a = 'àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœṕŕßśșțùúüûǘẃẍÿź·/_,:;'
    const b = 'aaaaaaaaceeeeghiiiimnnnoooooprssstuuuuuwxyz------'
    const p = new RegExp(a.split('').join('|'), 'g')

    return string.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-et-') // Replace & with ‘and’
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
}

// https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
export function copyToClipboard(str) {
    const el = document.createElement('textarea');  // Create a <textarea> element
    el.value = str;                                 // Set its value to the string that you want copied
    el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
    el.style.position = 'absolute';
    el.style.left = '-9999px';                      // Move outside the screen to make it invisible
    document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
    const selected =
        document.getSelection().rangeCount > 0        // Check if there is any content selected previously
            ? document.getSelection().getRangeAt(0)     // Store selection if found
            : false;                                    // Mark as false to know no selection existed before
    el.select();                                    // Select the <textarea> content
    document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el);                  // Remove the <textarea> element
    if (selected) {                                 // If a selection existed before copying
        document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
        document.getSelection().addRange(selected);   // Restore the original selection
    }
};

export function Now() {
    let now = new Date();
    now = parseInt(now.getTime() / 1000);
    return now;
}

export function DateDDMMYY(dateString) {
        
    String.prototype.replaceAll = function (search, replacement) {
        var target = this;
        return target.split(search).join(replacement);
    };
    
    let date = dateString.replaceAll("-", ",");
    date = date.replaceAll(":", ",");
    date = date.replace(".000000", "");
    date = date.replace(" ", ",");

    date = date.split(",")
    const year = parseInt(date[0]);
    const month = parseInt(date[1]) - 1 ;
    const day = parseInt(date[2]);
    const hours = parseInt(date[3]);
    const minutes = parseInt(date[4]);
    const seconds = parseInt(date[5]);
    date = new Date(Date.UTC(year, month, day, hours, minutes, seconds));

    return new Intl.DateTimeFormat('fr-FR', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit'
    }).format(date);
    
}

