import axios from 'axios';
import React from 'react';

export const SUBMIT_LOTO_INSERT = 'SUBMIT_LOTO_INSERT';
export const SUBMIT_LOTO_DELETE = 'SUBMIT_LOTO_DELETE';

export function actionSubmitLotoInsert(values) {
    const url = "/api-loto/insert";

    let data = new URLSearchParams();
    data.append("loto_form[drawDate]", values['drawDate']);
    data.append("loto_form[ball1]", values['ball1']);
    data.append("loto_form[ball2]", values['ball2']);
    data.append("loto_form[ball3]", values['ball3']);
    data.append("loto_form[ball4]", values['ball4']);
    data.append("loto_form[ball5]", values['ball5']);
    data.append("loto_form[star1]", values['star1']);

    return {
        type: SUBMIT_LOTO_INSERT,
        payload: axios.post(url, data)
    }
}

export function actionSubmitLotoDelete(values) {
    const url = "/api-loto/delete";

    let data = new URLSearchParams();
    data.append("loto_delete_form[drawDate]", values['drawDate']);

    return {
        type: SUBMIT_LOTO_DELETE,
        payload: axios.post(url, data)
    }
}