import React from 'react';
import {
    Route,
    Redirect,
    withRouter,
  } from 'react-router-dom';
import localforage from 'localforage';

class PrivateRoute extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isLogged: false,
            isAdmin: false,
            loaded: false,
        }
    }

    componentDidMount ()  {
        this.checkIsLogged(); 

    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname != this.props.location.pathname) {
            this.checkIsLogged(); 
        } 
    }

    checkIsLogged = () => {
        // check if session exists in storage
        // if so allows user in
        localforage.getItem('bda_session').then((value) => {

            if (value) {
                this.setState({
                    isLogged: true,
                    isAdmin: value.is_admin,
                    loaded: true,
                })
            } else {
                this.setState({
                    isLogged: false,
                    isAdmin: false,
                    loaded: true,
                })
            }
        })
    }

    render () {
        const { component: Component, ...rest } = this.props;
        const currentLocation = this.props.location.pathname;
        if (!this.state.loaded) return null;
        if (this.props.path.includes('/admin')) {
            if (!this.state.isLogged) {
                return <Redirect to={{
                    pathname: '/connexion',
                    state: {from: currentLocation}
                }}/>
            } else {
                return (  
                    <Route {...rest} 
                        render={props => { 
                            return this.state.isAdmin === true ? (
                                <Component {...props} />
                                ):(
                                <Redirect to='/' />
                                )
                        }}
                    />
                )
            }
        } else {
            return (  
                <Route {...rest} 
                    render={props => { 
                        return this.state.isLogged === true ? (
                            <Component {...props} />
                            ):(
                            <Redirect to={{
                                pathname: '/connexion',
                                state: currentLocation,
                            }}/>
                            )
                    }}
                />
            )
        }

    }
}



export default withRouter(PrivateRoute);

