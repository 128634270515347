import axios from 'axios';

export const SUBMIT_FIRST_NAME_EDIT_FORM = "SUBMIT_FIRST_NAME_EDIT_FORM";
export const SUBMIT_PASSWORD_EDIT_FORM = 'SUBMIT_PASSWORD_EDIT_FORM';
export const SUBMIT_EMAIL_EDIT_REQUEST_FORM = 'SUBMIT_EMAIL_EDIT_REQUEST_FORM';

export function actionSubmitFirstNameEditForm(values) {
    const url = "/api-user/first-name-edit";

    return {
        type: SUBMIT_FIRST_NAME_EDIT_FORM,
        payload: axios.post(url, values)
    }
}

export function actionSubmitPasswordEditForm(values) {
    const url = "/api-user/password-edit";

    return {
        type: SUBMIT_FIRST_NAME_EDIT_FORM,
        payload: axios.post(url, values)
    }
}

export function actionSubmitEmailEditRequestForm(values) {
    const url = "/api-user/email-edit-request";

    return {
        type: SUBMIT_EMAIL_EDIT_REQUEST_FORM,
        payload: axios.post(url, values)
    }
}







