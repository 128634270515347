import axios from 'axios';

export const GET_USER_DATA_FOR_OFFLINE = "GET_USER_DATA_FOR_OFFLINE";
export const GET_INITIAL_DATA_FOR_OFFLINE = "GET_INITIAL_DATA_FOR_OFFLINE";


export function actionGetUserDataForOffline(params) {
	const url = "/api-offline/get-offline-user";

	return {
		type: GET_USER_DATA_FOR_OFFLINE,
		payload: axios.post(url, params)
	}
}

export function actionGetInitialDataForOffline() {
	const url = "/api-offline/get-offline-initial";

	let data = {
		payload: "getInitialDataForOffline", // same as initial data
	}

	return {
		type: GET_INITIAL_DATA_FOR_OFFLINE,
		payload: axios.post(url, data)
	}
}
