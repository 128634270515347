import axios from 'axios';

export const GET_ALL_USERS = 'GET_ALL_USERS';
export const TOGGLE_ENABLE_USER = 'TOGGLE_ENABLE_USER';
export const SUBMIT_USER_EMAIL_EDIT_FORM = 'SUBMIT_USER_EMAIL_EDIT_FORM';
export const SUBMIT_USER_PAYMENT_INSERT_FORM = 'SUBMIT_USER_PAYMENT_INSERT_FORM';
export const SUBMIT_USER_REGISTRATION_BY_ADMIN_FORM = 'SUBMIT_USER_REGISTRATION_BY_ADMIN_FORM';

export function actionGetAllUsers() {
    const url = "/api-user/get-all";

    return {
        type: GET_ALL_USERS,
        payload: axios.get(url)
    }
}

export function actionToggleEnableUser(action, email) {
    const url = "/api-user/toggle-enable";
    let data = {
        action: action,
        email: email
    }

    return {
        type: TOGGLE_ENABLE_USER,
        payload: axios.post(url, data)
    }
}

export function actionSubmitUserEmailEditForm(values) {
    const url = "/api-user/email-edit";

    return {
        type: SUBMIT_USER_EMAIL_EDIT_FORM,
        payload: axios.post(url, values)
    }
}

export function actionSubmitUserPaymentInsertForm(values) {
    const url = "/api-payment/insert";

    return {
        type: SUBMIT_USER_PAYMENT_INSERT_FORM,
        payload: axios.post(url, values)
    }
}

export function actionSubmitUserRegistrationByAdminForm(values) {
    const url = "/api-user/registration-by-admin";

    return {
        type: SUBMIT_USER_REGISTRATION_BY_ADMIN_FORM,
        payload: axios.post(url, values)
    }
}
