import axios from 'axios';

export const GET_ALL_EUROMILLIONS_RESULTS = 'GET_ALL_EUROMILLIONS_RESULTS';

export function actionGetAllEuromillionsResults() {
    const url = `/api-euromillions/get-results`;

    let data = {
        number_of_draws: 'all'
    }

    return {
        type: GET_ALL_EUROMILLIONS_RESULTS,
        payload: axios.post(url, data)
    }
}