import axios from 'axios';
import React from 'react';

export const SUBMIT_LOGIN = 'SUBMIT_LOGIN';

export function actionSubmitLogin(values){
	const url = "/login_check";

    let data = {
        _email: values._username,
        _password: values._password,
        _remember_me: values._remember_me
    }

	return {
		type: SUBMIT_LOGIN,
		payload: axios.post(url, data)
	}
}