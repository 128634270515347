import axios from 'axios';

export const CHECK_SESSION = 'CHECK_SESSION';

// payload can be:
// last_active_client - to check/compare with server last active
// last_active_client can be unix time or zero, if no last_active_client exists
// extend - to extend session[last_active] if the user reacts to the session dialog prompt
// set_session_idle_logout_flag - to create the memcached flag to be checked on the redirected page (normally "/")
// check_session_idle_logout_flag - to check for the flag in order to show or not the dialog with the message " You have been disconnected due to innactivity....

export function actionCheckSession(payload){
    const url = "/api-session";
    
    let data = {
        payload: payload 
    }
	return {
		type: CHECK_SESSION,
		payload: axios.post(url, data)
	}
}

