import axios from 'axios';

export const SUBMIT_REGISTER = 'SUBMIT_REGISTER';
export const SUBMIT_PAYMENT_IN_FULL_INFO = 'SUBMIT_PAYMENT_IN_FULL_INFO';
export const CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION";

export function actionSubmitRegister(values){
	const url = "/register/";
	let data = new URLSearchParams();
	data.append("fos_user_registration_form[email]", values.fos_user_registration_form['email']);
	data.append("fos_user_registration_form[plainPassword][first]", values.fos_user_registration_form['plainPassword']['first']);
	data.append("fos_user_registration_form[plainPassword][second]", values.fos_user_registration_form['plainPassword']['second']);
    data.append("fos_user_registration_form[firstName]",values.fos_user_registration_form['firstName']);
	data.append("fos_user_registration_form[rgpd]", values.fos_user_registration_form['rgpd']);
	data.append("fos_user_registration_form[failedLogins]", 0); // TODO this should be set automatically server side
	return {
		type: SUBMIT_REGISTER,
		payload: axios.post(url, data)
	}
}

export function actionSubmitPaymentInFullInfo(values) {
	const url = "/api-payment/submit-payment-in-full-info";
	let data = values;

	return {
		type: SUBMIT_PAYMENT_IN_FULL_INFO,
		payload: axios.post(url, data)
	}
}

export function actionCreateSubscription(values) {
	const url = "/api-payment/create-subscription";
	let data = values;

	return {
		type: CREATE_SUBSCRIPTION,
		payload: axios.post(url, data)
	}
}
