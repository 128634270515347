import axios from 'axios';
import React from 'react';

export const GET_CUSTOM_PAGES = 'GET_CUSTOM_PAGES';

export function actionGetCustomPages() {
    const url = "/api-page/get-custom-pages";

    let data = {
        
    }

    return {
        type: GET_CUSTOM_PAGES,
        payload: axios.post(url, data)
    }
}