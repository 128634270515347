import axios from 'axios';

export const GET_EUROMILLIONS_RESULTS = 'GET_EUROMILLIONS_RESULTS';

export function actionGetEuromillionsResults() {
    const url = `/api-euromillions/get-results`;

    return {
        type: GET_EUROMILLIONS_RESULTS,
        payload: axios.get(url)
    }
}