import axios from 'axios';


export const GET_SUBSCRIPTION_DETAILS = 'GET_SUBSCRIPTION_DETAILS';

export function actionGetSubscriptionDetails(values) {
    const url = "/api-payment/subscription-details";
    let data = values;
    return {
        type: GET_SUBSCRIPTION_DETAILS,
        payload: axios.post(url, data)
    }
}